import { useMixpanel } from "react-mixpanel-browser";

// Custom hook for identifying users
export const useMixpanelIdentify = () => {
    const mixpanel = useMixpanel();

    const mixpanelIdentify = ({ email, feduid, phoneNumber, userName }: { email: string, feduid: string, phoneNumber: string, userName: string }) => {
        if (mixpanel.config.token) {
            // Identify the user by their distinct ID
            mixpanel.identify(mixpanel.get_distinct_id());

            // Set user properties in Mixpanel People profile
            mixpanel.people.set({
                email: email,
                feduid: feduid,
                name: userName,
                phone: phoneNumber,
            });

            // Set email as a default Mixpanel property
            mixpanel.people.set("$email", email);

            // Register properties as super properties
            mixpanel.register({
                name: userName,
                email: email,
                phone: phoneNumber,
            });
        }
    };

    const track = ({ pageName, channel }: { pageName: string, channel: string }) => {
        mixpanel.track("PageView", {
            pageName: pageName,
            channel: channel
        });
    }

    return { mixpanelIdentify, track };
};
