import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import * as GrIcons from "react-icons/gr";
import { useWizard } from "react-use-wizard";
import { toast } from "react-toastify";
import { makeGetRequest } from "../../services/apiHandler";
import { useDispatch, useSelector } from "react-redux";
import { stepActions } from "../../store/Steps";
import { getIndividualPayloadActions } from "../../store/IndividualPayload";
import { variants } from "../../shared/variants";
import { travelCurrentScreenActions } from "../../store/CurrentTravelScreen";
import makeAnimated from "react-select/animated";
import {
  onValidEmailAddress,
  onValidatePhoneNumber,
} from "../../services/app.services";
import { useMixpanelIdentify } from "../../services/mixpanel.service";

const animatedComponents = makeAnimated();

function IndividualDetails({
  setCurrentScreen,
  individualStatus,
  groupStatus,
}: any) {
  const individualPayload = useSelector(
    (state: any) => state.individualData.individual
  );

  const { mixpanelIdentify } = useMixpanelIdentify()

  const travel_option = sessionStorage.getItem("travel_option")!;
  const dispatch = useDispatch();

  const { previousStep, nextStep } = useWizard();

  const [userInfor, setUserInfor] = useState({
    userName: "",
    email: "",
    phoneNumber: "",
  });

  const [travelInfo, setTravelInfo] = useState({
    startDate: individualPayload?.startDate ? individualPayload?.startDate : "",
    endDate: individualPayload?.endDate ? individualPayload?.endDate : "",
    userName: "",
    phoneNumber: "",
    location: "",
    yearOfBirth: individualPayload?.yearOfBirth
      ? individualPayload?.yearOfBirth
      : "",
    reason: individualPayload?.reason ? individualPayload?.reason : "",
    departureCountry:
      travel_option == "inbound" || travel_option == "outbound" ? "Kenya" : "",
    destinationCountry:
      travel_option == "inbound" || travel_option == "outbound"
        ? individualPayload?.destinationCountry
        : "",
  });
  const defaultReason = {
    value: individualPayload?.reason ? individualPayload?.reason : "",
    label: individualPayload?.reason ? individualPayload?.reason : "",
  };

  const reasons: any = [
    {
      name: "BUSINESS",
      label: "Business",
    },
    {
      name: "TOURIST",
      label: "Tourist",
    },
    {
      name: "STUDIES",
      label: "Studies",
    },

    {
      name: "MEDICAL",
      label: "Medical",
    },
  ];
  const [countries, setCountries] = useState<any[]>();

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setTravelInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputInboundChange = (event: any) => {
    let destinations = [{ country: "Kenya" }];
    setTravelInfo((prevState) => ({
      ...prevState,
      destinationCountry: destinations,
    }));
  };

  const handleChange = (selectedOption: any) => {
    setTravelInfo((previousState: any) => ({
      ...previousState,
      reason: selectedOption.name,
    }));
  };

  const handleCountriesChange = (selectedOption: any) => {
    let destination = selectedOption?.map((option) => {
      let destination = { ...option };
      destination["country"] = destination?.value;
      destination["town"] = "unknown";
      delete destination.value;
      delete destination.label;
      return destination;
    });
    setTravelInfo((previousState: any) => ({
      ...previousState,
      destinationCountry: destination,
    }));
  };

  const handleInboundCountriesChange = (selectedOption: any) => {
    setTravelInfo((previousState: any) => ({
      ...previousState,
      departureCountry: selectedOption.value,
    }));
  };

  const handleUserInforInputChange = (event: any) => {
    const { name, value } = event.target;
    console.log(name, value);
    setUserInfor((previousState) => ({ ...previousState, [name]: value }));
  };

  const saveDetails = () => {
    if (!travelInfo.startDate) {
      toast.warning("Enter Travelling date!");
      document.getElementById("startDate")?.focus;
      return;
    }
    if (!travelInfo.reason) {
      toast.warning("Please select a reason");
      document.getElementById("reason")?.focus;
      return;
    }
    if (!travelInfo.destinationCountry) {
      toast.warning("Enter Destination Country!");
      document.getElementById("destinationCountry")?.focus;
      return;
    }
    if (!travelInfo.departureCountry) {
      toast.warning("Enter Departure Country!");
      document.getElementById("departureCountry")?.focus;
      return;
    }

    if (!travelInfo.yearOfBirth) {
      toast.warning("Enter Date of Birth!");
      document.getElementById("yearOfBirth")?.focus;
      return;
    }
    if (!travelInfo.endDate) {
      toast.warning("Enter a valid end date");
      document.getElementById("endDate")?.focus;
      return;
    }
    let endDate = new Date(travelInfo.endDate);
    let startDate = new Date(travelInfo.startDate);
    if (endDate < startDate) {
      toast.warning("Please select a valid end date");
      return;
    }

    let date = travelInfo.startDate.replaceAll("-", "/");
    let travelDate = date.split("/").reverse().join("/");

    let travelAddedendDate = travelInfo.endDate.replaceAll("-", "/");
    let travelendDate = travelAddedendDate.split("/").reverse().join("/");

    sessionStorage.setItem("startDate", travelDate);
    sessionStorage.setItem("endDate", travelendDate);

    sessionStorage.setItem("travelInfo", JSON.stringify(travelInfo));
    sessionStorage.setItem("travel_type", "Individual");
    proceedUserDetails();

    if (dispatch(stepActions.onAddStep())) {
      nextStep();
      dispatch(getIndividualPayloadActions.setIndividualPayload(travelInfo));
    }
  };

  const proceedUserDetails = () => {
    if (!userInfor.userName) {
      toast.warning("Enter name");
      document.getElementById("userName")?.focus;
      return;
    }
    if (!onValidEmailAddress(userInfor.email)) {
      document.getElementById("email")?.focus();
      toast.warning("Email Address required or invalid");
      return;
    }
    if (!onValidatePhoneNumber(userInfor.phoneNumber)) {
      toast.warning("Phone number required or invalid");
      document.getElementById("phoneNumber")?.focus();
      return;
    }

    mixpanelIdentify({
      email: userInfor.email,
      phoneNumber: userInfor.phoneNumber,
      userName: userInfor.userName,
      feduid: "",
    });
    
  };
  const getCountries = async () => {
    const result: IApiResponse = await makeGetRequest(
      `${process.env.REACT_APP_TRAVEL_API_URL}/countries`
    );
    if (result.Status == 200) {
      let countries: any[] = result.Payload;
      countries.map((country) => {
        country["value"] = country?.name;
        delete country?.name;
        return country;
      });
      setCountries(countries);
    } else {
      toast.warning(result.Payload);
    }
  };

  const getTravellingOption = (): string => {
    if (travel_option == "inbound" || travel_option == "outbound") {
      return "country";
    }
    return "town";
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed, so we add 1 and pad with '0' if needed.
    const day = String(today.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const getMinimumBirthDate = () => {
    const today = new Date();
    today.setFullYear(today.getFullYear() - 18); // Subtract 18 years from the current year

    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const setIndividualScreen = () => {
    setCurrentScreen("individual");
    dispatch(travelCurrentScreenActions.setCurrentScreen("individual"));
  };

  const setGroupScreen = () => {
    setCurrentScreen("group");
    dispatch(travelCurrentScreenActions.setCurrentScreen("group"));
  };

  const setFamilyScreen = () => {
    setCurrentScreen("family");
    dispatch(travelCurrentScreenActions.setCurrentScreen("family"));
  };

  const initializeInbound = () => {
    if (travel_option == "inbound") {
      let destinations = [{ country: "Kenya", town: "unknown" }];
      setTravelInfo((prevState) => ({
        ...prevState,
        destinationCountry: destinations,
      }));
    }
  };
  useEffect(() => {
    getCountries();
    initializeInbound();
  }, []);
  return (
    <motion.div variants={variants} initial="enter" animate="center">
      <div className="container mb-3">
        <div className="row d-flex justify-content-between align-items-center">
          <div className="col-md-5">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="coverType">
                  What type of cover do you want?
                </label>
                <br />
                <div className="family-type-selection">
                  <div
                    className="individual"
                    onClick={(e) => {
                      e.preventDefault();
                      setIndividualScreen();
                    }}
                  >
                    <input
                      id="coverType"
                      name="coverType"
                      type="radio"
                      checked={individualStatus}
                      onChange={handleInputChange}
                      value={"individual"}
                    />
                    <span className="ky-individual">Individual</span>
                  </div>
                  <div
                    className="group"
                    onClick={(e) => {
                      e.preventDefault();
                      setGroupScreen();
                    }}
                  >
                    <input
                      id="coverType"
                      name="coverType"
                      type="radio"
                      checked={groupStatus}
                      onChange={handleInputChange}
                      value={travelInfo.startDate}
                    />
                    <span className="ky-group">Group</span>
                  </div>
                  {travel_option == "outbound" && (
                    <div
                      className="group"
                      onClick={(e) => {
                        e.preventDefault();
                        setFamilyScreen();
                      }}
                    >
                      <input
                        id="coverType"
                        name="coverType"
                        type="radio"
                        checked={groupStatus}
                        onChange={handleInputChange}
                        value={travelInfo.startDate}
                      />
                      <span className="ky-group">Family</span>
                    </div>
                  )}
                </div>
              </motion.div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="startDate">
                  When are you travelling?{" "}
                </label>
                <br />
                <input
                  id="startDate"
                  name="startDate"
                  type="date"
                  onChange={handleInputChange}
                  value={travelInfo.startDate}
                  min={getCurrentDate()}
                ></input>
              </motion.div>
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-between align-items-center">
          <div className="col-md-5">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="reason">
                  Please tell us your reason for travelling{" "}
                </label>
                <br />
                <Select
                  options={reasons}
                  onChange={handleChange}
                  id={"destinationCountry"}
                  className={"destinationCountry"}
                  defaultValue={defaultReason}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      backgroundColor: "#F6F8FB",
                      border: state.isFocused ? "none" : "#F6F8FB",
                    }),
                    placeholder: (baseStyles, state) => ({
                      ...baseStyles,
                    }),
                    input: (baseStyles, state) => ({
                      ...baseStyles,
                      width: "315px",
                      height: "47px",
                    }),
                    dropdownIndicator: (baseStyles, state) => ({
                      ...baseStyles,
                      color: "#00000",
                      fontWeight: "bold",
                    }),
                    container: (baseStyles, state) => ({
                      ...baseStyles,
                      border: "none",
                    }),
                  }}
                />{" "}
              </motion.div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="endDate">
                  When are you coming back?
                </label>
                <br />
                <input
                  id="endDate"
                  name="endDate"
                  type="date"
                  onChange={handleInputChange}
                  value={travelInfo.endDate}
                  min={getCurrentDate()}
                ></input>
              </motion.div>
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-between align-items-center">
          <div className="col-md-5">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="yearOfBirth">
                  When is your birth date?{" "}
                </label>
                <br />
                <input
                  id="yearOfBirth"
                  name="yearOfBirth"
                  type="date"
                  onChange={handleInputChange}
                  value={travelInfo.yearOfBirth}
                  max={getMinimumBirthDate()}
                ></input>
              </motion.div>
            </div>
          </div>
          <div className="col-md-5">
            {(travel_option == "outbound" || travel_option == "town") && (
              <div className="ky-input-wrapper">
                <motion.div
                  animate={{ scale: [-0.7, 1] }}
                  transition={{ duration: 1 }}
                  className={
                    getTravellingOption() !== "town"
                      ? "ky-input-container mt-4 country-travel"
                      : "ky-input-container mt-4"
                  }
                >
                  <label
                    className="text-left-label mb-2"
                    htmlFor="departureCountry"
                  >
                    What {getTravellingOption()} are you travelling from
                    (departure)?
                  </label>
                  <br />
                  <input
                    id="departureCountry"
                    name="departureCountry"
                    type="text"
                    className={
                      getTravellingOption() !== "town" ? "country-travel" : ""
                    }
                    onChange={handleInputChange}
                    value={travelInfo.departureCountry}
                    placeholder="Type location"
                  ></input>
                </motion.div>
              </div>
            )}
            {travel_option == "inbound" && (
              <div className="ky-input-wrapper">
                <motion.div
                  animate={{ scale: [-0.7, 1] }}
                  transition={{ duration: 1 }}
                  className="ky-input-container mt-4"
                >
                  <label className="text-left-label mb-2" htmlFor="reason">
                    What {getTravellingOption()} are you travelling from
                    (departure)?
                  </label>
                  <br />
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    // isMulti
                    options={countries}
                    onChange={handleInboundCountriesChange}
                    // value={selectedCountries}
                    id={"destinationCountry"}
                    className={"destinationCountry"}
                    // defaultValue={loadDestinationCountries()}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: "#F6F8FB",
                        border: state.isFocused ? "none" : "#F6F8FB",
                      }),

                      dropdownIndicator: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "#00000",
                        fontWeight: "bold",
                      }),
                      container: (baseStyles, state) => ({
                        ...baseStyles,
                        border: "none",
                      }),
                    }}
                  />{" "}
                </motion.div>
              </div>
            )}
          </div>
        </div>
        <div className="row d-flex justify-content-between align-items-center">
          {getTravellingOption() !== "town" && (
            <>
              {travel_option == "outbound" && (
                <div className="col-md-5">
                  <div className="ky-input-wrapper">
                    <motion.div
                      animate={{ scale: [-0.7, 1] }}
                      transition={{ duration: 1 }}
                      className="ky-input-container mt-4"
                    >
                      <label className="text-left-label mb-2" htmlFor="reason">
                        What is the final country you are travelling to
                        (arrival)?{" "}
                      </label>
                      <br />
                      <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        options={countries}
                        onChange={handleCountriesChange}
                        // value={selectedCountries}
                        id={"destinationCountry"}
                        className={"destinationCountry"}
                        // defaultValue={loadDestinationCountries()}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: "#F6F8FB",
                            border: state.isFocused ? "none" : "#F6F8FB",
                          }),

                          dropdownIndicator: (baseStyles, state) => ({
                            ...baseStyles,
                            color: "#00000",
                            fontWeight: "bold",
                          }),
                          container: (baseStyles, state) => ({
                            ...baseStyles,
                            border: "none",
                          }),
                        }}
                      />{" "}
                    </motion.div>
                  </div>
                </div>
              )}
              {travel_option == "inbound" && (
                <>
                  <div className="col-md-5">
                    <div className="ky-input-wrapper">
                      <motion.div
                        animate={{ scale: [-0.7, 1] }}
                        transition={{ duration: 1 }}
                        className={
                          getTravellingOption()
                            ? "ky-input-container mt-4 country-travel"
                            : "ky-input-container mt-4"
                        }
                      >
                        <label
                          className="text-left-label mb-2"
                          htmlFor="departureCountry"
                        >
                          What is the final country you are travelling to
                          (arrival)?{" "}
                        </label>
                        <br />
                        <input
                          id="destinationCountry"
                          name="destinationCountry"
                          type="text"
                          className={
                            getTravellingOption() ? "country-travel" : ""
                          }
                          onChange={handleInputInboundChange}
                          value={"Kenya"}
                          placeholder="Type location"
                        ></input>
                      </motion.div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {travel_option == "town" && (
            <div className="col-md-5">
              <div className="ky-input-wrapper">
                <motion.div
                  animate={{ scale: [-0.7, 1] }}
                  transition={{ duration: 1 }}
                  className="ky-input-container mt-4"
                >
                  <label
                    className="text-left-label mb-2"
                    htmlFor="destinationCountry"
                  >
                    What is the final {travel_option} you are travelling to
                    (arrival)?
                  </label>
                  <br />
                  <input
                    id="destinationCountry"
                    name="destinationCountry"
                    type="text"
                    onChange={handleInputChange}
                    value={travelInfo.destinationCountry}
                    placeholder="Type location"
                  ></input>
                </motion.div>
              </div>
            </div>
          )}
        </div>
        <div className="row d-flex justify-content-between align-items-center">
          {getTravellingOption() !== "town" && (
            <>
              {travel_option == "outbound" && (
                <div className="col-md-5">
                  <div className="ky-input-wrapper">
                    <motion.div
                      animate={{ scale: [-0.7, 1] }}
                      transition={{ duration: 1 }}
                      className="ky-input-container mt-4"
                    >
                      <label className="text-left-label mb-2" htmlFor="reason">
                        What is the final country you are travelling to
                        (arrival)?{" "}
                      </label>
                      <br />
                      <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        options={countries}
                        onChange={handleCountriesChange}
                        // value={selectedCountries}
                        id={"destinationCountry"}
                        className={"destinationCountry"}
                        // defaultValue={loadDestinationCountries()}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: "#F6F8FB",
                            border: state.isFocused ? "none" : "#F6F8FB",
                          }),

                          dropdownIndicator: (baseStyles, state) => ({
                            ...baseStyles,
                            color: "#00000",
                            fontWeight: "bold",
                          }),
                          container: (baseStyles, state) => ({
                            ...baseStyles,
                            border: "none",
                          }),
                        }}
                      />{" "}
                    </motion.div>
                  </div>
                </div>
              )}
            </>
          )}
          <div className="col-md-5">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="userName">
                  Your Name
                </label>
                <br />
                <input
                  id="userName"
                  name="userName"
                  type="text"
                  value={userInfor.userName}
                  onChange={handleUserInforInputChange}
                  placeholder="Type Name"
                ></input>
              </motion.div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="phoneNumber">
                  Your Phone Number
                </label>
                <br />
                <input
                  id="phoneNumber"
                  name="phoneNumber"
                  type="text"
                  value={userInfor.phoneNumber}
                  onChange={handleUserInforInputChange}
                  placeholder="Type Phone Number"
                ></input>
              </motion.div>
            </div>
          </div>

          <div className="col-md-5">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="email">
                  Your Email
                </label>
                <br />
                <input
                  id="email"
                  name="email"
                  type="email"
                  onChange={handleUserInforInputChange}
                  value={userInfor.email}
                  placeholder="Type Email"
                ></input>
              </motion.div>
            </div>
          </div>
        </div>
      </div>

      <div className="ky-inputs-container-1">
        <div className="mt-2 pointer-button">
          <div className="previous">
            <span
              onClick={() => {
                dispatch(stepActions.onRemoveStep());
                previousStep();
              }}
            >
              <span className="GrIcon me-2">
                <GrIcons.GrFormPreviousLink />
              </span>{" "}
              <span className="previous">Previous</span>
            </span>
          </div>
          <button className="next-btn" onClick={() => saveDetails()}>
            {" "}
            <span>
              Next{" "}
              <span className="GrIcon ms-3">
                <GrIcons.GrFormNextLink />
              </span>
            </span>{" "}
          </button>
        </div>
      </div>
    </motion.div>
  );
}

export default IndividualDetails;
