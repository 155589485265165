export const setSessionData = (key: string, data: any) => {
  let d = getSessionData(key);
  let od = { ...d, ...data };
  sessionStorage.setItem(key, JSON.stringify(od));
};

export const getSessionData = (key: string) => {
  let d = sessionStorage.getItem(key);
  if (d) {
    return JSON.parse(d);
  }
  return {};
};

export const onValidatePhoneNumber = (phoneNumber: any) => {
  const status = /^(\+254|0)[1-9]\d{8}$/.test(phoneNumber);
  return status;
};

export const onValidEmailAddress = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};
